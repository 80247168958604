<template>
  <div class="mt-3 px-0">
    <v-card tile class="pb-3" flat>
      <v-row align="center">
        <v-col class="ml-3">
          <v-card-title>Edit Time Sheet</v-card-title>
        </v-col>
        <v-col>
          <div class="d-flex align-center" style="gap: 20px">
            <v-icon outlined fab @click="goToPrevious">
              mdi mdi-arrow-left-drop-circle-outline</v-icon
            >
            <span style="font-size: 1rem; font-weight: 500">{{
              currentDate
            }}</span>
            <v-icon outlined fab @click="goToNext">
              mdi mdi-arrow-right-drop-circle-outline</v-icon
            >
          </div>
        </v-col>
      </v-row>
      <div class="pa-5">
        <v-card class="pa-3" flat style="background-color: #f0f4f8">
          <v-form @submit.prevent="submitForm">
            <v-row>
              <v-col cols="3">
                <div style="color: #787486" class="font-weight-500">
                  Projects
                </div>
              </v-col>
              <v-col cols="5">
                <div style="color: #787486" class="font-weight-500">
                  Task Detail
                </div>
              </v-col>
              <v-col cols="2">
                <div style="color: #787486" class="font-weight-500">
                  Task Status
                </div>
              </v-col>
              <v-col cols="2">
                <div style="color: #787486" class="font-weight-500">Hours</div>
              </v-col>
            </v-row>
            <v-row
              v-for="(row, index) in entries"
              :key="row.id"
              class="align-start"
            >
              <v-col cols="3">
                <v-autocomplete
                  background-color="white"
                  clearable
                  flat
                  solo
                  height="60px"
                  style="border: none"
                  dense
                  label="Select Assign Project"
                  item-text="name"
                  item-value="project_id"
                  :items="allProjects"
                  v-model="row.project_id"
                  :error-messages="errors[`[${index}].project_id`] || ''"
                  @blur="() => removeError(`[${index}].project_id`)"
                  @focus="() => removeError(`[${index}].project_id`)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-textarea
                  solo
                  flat
                  background-color="white"
                  rows="2"
                  v-model="row.task"
                  :error-messages="errors[`[${index}].task`] || ''"
                  @blur="() => removeError(`[${index}].task`)"
                  @focus="() => removeError(`[${index}].task`)"
                ></v-textarea>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  background-color="white"
                  clearable
                  dense
                  solo
                  flat
                  height="60px"
                  label="Select Task Status"
                  item-text="name"
                  item-value="status_id"
                  :items="status"
                  v-model="row.status_id"
                  :error-messages="errors[`[${index}].status_id`] || ''"
                  @blur="() => removeError(`[${index}].status_id`)"
                  @focus="() => removeError(`[${index}].status_id`)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <span class="ketan">
                  <v-menu
                    :ref="'menu_' + index"
                    v-model="row.menu2"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    :nudge-bottom="65"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        background-color="white"
                        flat
                        solo
                        height="60px"
                        dense
                        placeholder="00:00"
                        v-model="row.time"
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors[`[${index}].time`] || ''"
                        @blur="() => removeError(`[${index}].time`)"
                        @focus="() => removeError(`[${index}].time`)"
                      ></v-text-field>
                    </template>
                    <v-sheet
                      color="grey-lighten-3"
                      :elevation="12"
                      style="border-radius: 5px"
                    >
                      <div
                        class="d-flex align-center justify-space-between pa-4"
                      >
                        <div
                          style="width: 130px; flex-direction: column"
                          class="d-flex text-center"
                        >
                          <div>
                            <v-icon
                              size="30"
                              @click="increment('hours', index)"
                            >
                              mdi mdi-chevron-up
                            </v-icon>
                          </div>

                          <div class="hoursMinutes">{{ row.hours }}</div>

                          <div>
                            <v-icon
                              size="30"
                              @click="decrement('hours', index)"
                            >
                              mdi mdi-chevron-down
                            </v-icon>
                          </div>
                        </div>
                        <div>:</div>
                        <div
                          style="width: 100px; flex-direction: column"
                          class="text-center"
                        >
                          <div>
                            <v-icon
                              size="30"
                              @click="increment('minutes', index)"
                            >
                              mdi mdi-chevron-up
                            </v-icon>
                          </div>
                          <div class="hoursMinutes">{{ row.minutes }}</div>
                          <div>
                            <v-icon
                              size="30"
                              @click="decrement('minutes', index)"
                            >
                              mdi mdi-chevron-down
                            </v-icon>
                          </div>
                        </div>
                      </div>
                    </v-sheet>
                  </v-menu>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-row class="mt-5 d-flex justify-center align-center">
          <v-col cols="3">
            <!-- <span class="font-size-14 font-weight-400"
              >Email to : nilesh@nexuslinkservices.com</span
            > -->
          </v-col>
          <v-col cols="5">
            <div class="d-flex justify-center align-center" style="gap: 20px">
              <!-- <div>Cc</div>
              <v-autocomplete
                background-color="white"
                multiple
                chips
                clearable
                outlined
                dense
                label="Select Users"
                item-text="name"
                item-value="id"
                hide-details
                :items="employees"
                v-model="emailCC"
              ></v-autocomplete> -->
            </div>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col class="d-flex justify-center">
            <span class="font-size-14 font-weight-400">
              Total Hours: {{ convertToTimeString(totalTime) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col class="d-flex justify-start" style="gap: 20px">
            <v-btn depressed color="primary" @click="submitForm">Submit</v-btn>
            <v-btn depressed @click="back" color="secondary">Back</v-btn>
          </v-col>
          <v-spacer />

          <v-col class="d-flex justify-end" style="gap: 20px">
            <v-btn depressed @click="addRow" color="primary">Add Row</v-btn>

            <v-btn depressed @click="removeRow" color="secondary"
              >Delete Row</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <!-- for success message -->
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        color="green accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <!-- for error message -->
      <v-snackbar
        v-model="snackbarError"
        :timeout="3000"
        color="deep-orange accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarError = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex/dist/vuex.common.js";
import * as Yup from "yup";

// Validation schema for Time Sheet
const validationSchema = Yup.array().of(
  Yup.object().shape({
    project_id: Yup.string(),
    task: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Task is required"),
      otherwise: (schema) => schema.nullable(),
    }),

    status_id: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Status is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    time: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  })
);

export default {
  data() {
    return {
      currentDate: moment(this.$route.params.date, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      entries: [
        {
          id: Math.random().toString(36).substring(2, 7),
          project_id: "",
          task: null,
          status_id: null,
          time: null,
          menu2: false,
          modal2: false,
          hours: 0,
          minutes: 0,
        },
      ],
      snackbar: false,
      snackbarError: false,
      message: "",
      emailCC: [],
      totalTime: 0,
      errors: {},
      selectedTime: null,
      status: [
        { name: "Pending", status_id: 0 },
        { name: "Completed", status_id: 1 },
      ],
    };
  },
  computed: {
    ...mapState("projects", ["allProjects"]),
  },
  watch: {
    entries: {
      handler(newVal) {
        this.totalTime = 0;
        newVal.forEach((row, index) => {
          this.timeChange(
            `${row.hours < 10 ? "0" + row.hours : row.hours}:${
              row.minutes == 0 ? "0" + row.minutes : row.minutes
            }`,
            index
          );
          this.totalTime =
            this.totalTime + this.convertToMinutes(row.time) || 0;
        });
      },
      deep: true,
    },
    "$route.params.date": {
      handler() {
        this.getUserTimeSheet();
      },
    },
  },
  methods: {
    ...mapMutations("projects", ["setProjectData"]),

    // Function for change date
    goToNext() {
      const nextDate = moment(this.currentDate, "DD-MM-YYYY").add(1, "days");
      if (nextDate.date() === 1) {
        nextDate.add(1, "days").startOf("month");
      }
      this.currentDate = nextDate.format("DD-MM-YYYY");
      this.$router.push(
        `/admin/timeSheet/edit/${moment(this.currentDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )}/${this.$route.params.empId}`
      );
    },
    goToPrevious() {
      const prevDate = moment(this.currentDate, "DD-MM-YYYY").subtract(
        1,
        "days"
      );
      if (
        prevDate.date() ===
        moment(this.currentDate, "DD-MM-YYYY").startOf("month").date()
      ) {
        prevDate.startOf("month");
      }
      this.currentDate = prevDate.format("DD-MM-YYYY");
      this.$router.push(
        `/admin/timeSheet/edit/${moment(this.currentDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )}/${this.$route.params.empId}`
      );
    },
    increment(field, index) {
      if (field === "hours") {
        this.entries[index].hours = (this.entries[index].hours + 1) % 24;
      } else if (field === "minutes") {
        this.entries[index].minutes += 15;
        if (this.entries[index].minutes >= 60) {
          this.entries[index].minutes = 0;
          this.entries[index].hours = (this.entries[index].hours + 1) % 24;
        }
      }
    },
    decrement(field, index) {
      if (field === "hours") {
        this.entries[index].hours = (this.entries[index].hours - 1 + 24) % 24;
      } else if (field === "minutes") {
        this.entries[index].minutes -= 15;
        if (this.entries[index].minutes < 0) {
          this.entries[index].minutes = 45;
          this.entries[index].hours = (this.entries[index].hours - 1 + 24) % 24;
        }
      }
    },
    // Function for set time select on time piker
    timeChange(e, index) {
      this.entries[index]["time"] = e;
    },

    // Function for convert time into minutes
    convertToMinutes(timeString) {
      const [hours, minutes] = timeString?.split(":").map(Number);
      return hours * 60 + minutes;
    },

    // Function for convert minutes into time String 10:30
    convertToTimeString(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },

    // Go to previous page
    back() {
      this.$router.back("/add");
    },

    redirect() {
      this.$router.push({
        path: "/timeSheet",
      });
    },

    // Add new  input row in time sheet
    addRow() {
      this.entries.push({
        id: Math.random().toString(36).substring(2, 7),
        project_id: "",
        task: "",
        status_id: null,
        time: null,
        menu2: false,
        modal2: false,
        hours: 0,
        minutes: 0,
      });
    },

    // Remove time sheet input row
    removeRow() {
      this.entries.splice(this.entries.length - 1, 1);
    },

    getErrors(field) {
      return this.errors.filter((err) => err.includes(field));
    },
    removeError(key) {
      delete this.errors[key];
    },

    // ** API services for add Time Sheet
    async submitForm() {
      validationSchema
        .validate(this.entries, { abortEarly: false })
        .then(() => {
          this.errors = {};
          const { empId, date } = this.$route.params;

          // Payload for API
          const tempData = this.entries
            .filter(
              (d) => d.project_id && d.task && (d.status_id == 0 || 1) && d.time
            )
            .map((d) => ({
              project_id: d.project_id,
              status: d.status_id,
              time: this.convertToMinutes(d.time),
              task: d.task,
            }));

          this.$axios
            .put("/time-sheet/", {
              data: tempData,
              date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
              employes_id: empId,
            })
            .then((res) => {
              this.loading = false;

              if (res.data.success) {
                this.snackbar = true;
                this.message = res.data.message;
                setTimeout(() => {
                  this.redirect();
                }, 2000);
              } else {
                this.snackbarError = true;
                this.message = res.data.message;
              }
            });
        })
        .catch((err) => {
          console.log(err, "errerrerr");
          err?.inner?.forEach((error) => {
            this.errors = {
              ...this.errors,

              [error.path]: error.message,
            };
          });
        });
    },

    // ** API Services for get user time sheet from the date
    async getUserTimeSheet() {
      const { empId, date } = this.$route.params;

      let _self = this;
      try {
        const response = await this.$axios.post("/time-sheet/edit", {
          date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
          employes_id: empId,
        });

        this.loading = false;

        if (response.data.success) {
          const data = response.data.timeSheet;
          if (!data.entries) {
            _self.entries = [
              {
                id: Math.random().toString(36).substring(2, 7),
                project_id: "",
                task: "",
                status_id: null,
                time: null,
                menu2: false,
                modal2: false,
                hours: 0,
                minutes: 0,
              },
            ];
            return;
          }
          const entries = data?.entries.map((d) => ({
            project_id: d.project_id,
            status_id: parseInt(d.status),
            time: this.convertToTimeString(d.time),
            task: d.task,
            hours: this.convertMinutesToHoursAndMinutes(d.time)?.hours || 0,
            minutes: this.convertMinutesToHoursAndMinutes(d.time)?.minutes || 0,
          }));

          _self.entries = entries;
        } else {
          this.snackbarError = true;
          this.message = response.data.message;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    convertMinutesToHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return { hours, minutes };
    },
    // API service for the project list
    get_project_list() {
      if (this.allProjects.length) return;
      this.loading = true;
      this.$axios.get("project").then((res) => {
        this.setProjectData(res.data.projects);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.get_project_list();
    this.getUserTimeSheet();
  },
};
</script>

<style>
.ketan-time-picker .v-picker__body {
  max-height: 180px !important;
}
.ketan-time-picker .v-picker__body .v-time-picker-clock__item {
  font-size: 12px;
  width: 20px;
  height: 20px;
}
.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-color: #f4f6f7;
}
.hoursMinutes {
  border: 2px solid #b7b7b7 !important;
  padding: 5px 30px 5px 30px !important;
  border-radius: 5px !important;
}
</style>
